@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  font-family: "Poppins", sans-serif;
  font-weight: 500;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}

svg, img{
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-drag: none;
  user-drag: none;
}

html{
  scroll-behavior: smooth;
}

#contact-us{
  scroll-margin: 200px;
}

@layer base {
  body[themeStyle="dark"]{
    @apply text-accentWhite bg-accentDark;
  }
  body[themeStyle="white"]{
    @apply text-accentDark bg-accentWhite;
  }
  body[themeStyle="white"] .header {
    background: linear-gradient(
      270deg,
      rgba(248, 255, 255, 0) 0%,
      #f8ffff 19.16%,
      #f8ffff 48.88%,
      #f8ffff 82.18%,
      rgba(248, 255, 255, 0) 100%
    );
  }
  body[themeStyle="dark"] .header {
    background: linear-gradient(
      270deg,
      rgba(34, 31, 44, 0) 0%,
      #221f2c 19.16%,
      #221f2c 48.88%,
      #221f2c 82.18%,
      rgba(34, 31, 44, 0) 100%
    );
  }
  body[themeStyle="dark"] .submenu__wrapper::after {
    background: url(media/images/arrow-top-dark.svg) center center/50px 35px
      no-repeat;
  }
  body[themeStyle="dark"] .lang_list-wrapper::before {
    background: url(media/images/arrow-top-lang.svg) center center/50px 35px
      no-repeat;
  }
  body[themeStyle="white"] .submenu__wrapper::after,
  body[themeStyle="white"] .lang_list-wrapper::before {
    background: url(media/images/arrow-top-white.svg) center center/50px 35px
      no-repeat;
  }
  body[themeStyle="dark"] .header__menu-nav {
    background: rgba(34, 31, 44);
  }
  body[themeStyle="white"] .header__menu-nav {
    background: rgba(246, 255, 253);
  }
  body[themeStyle="dark"] .mobile__menu-details summary svg path {
    stroke: white;
  }
  body[themeStyle="dark"] .hero-section::after {
    background: linear-gradient(
      180.16deg,
      rgba(34, 31, 44, 0) 9.5%,
      rgba(34, 31, 44, 0.9) 67.84%,
      #221f2c 83.57%,
      #221f2c 99.86%
    );
  }
  body[themeStyle="white"] .hero-section::after {
    background: linear-gradient(
      180.16deg,
      rgba(248, 255, 255, 0) 9.5%,
      rgba(248, 255, 255, 0.9) 67.84%,
      #f8ffff 83.57%,
      #f8ffff 99.86%
    );
  }
  body[themeStyle="white"] .input::placeholder {
    color: #221f2c;
  }
  body[themeStyle="dark"] .news-form__input::placeholder {
    color: rgba(255, 255, 255, 0.7);
  }
  body[themeStyle="white"] .news-form__input::placeholder {
    color: rgba(47, 47, 53, 0.7);
  }
  body[themeStyle="dark"] .news-form__input {
    color: rgba(255, 255, 255, 0.7);
  }
  body[themeStyle="white"] .news-form__input {
    color: rgba(47, 47, 53, 0.7);
  }
  body[themeStyle="dark"] .news-form__input {
    color: rgba(255, 255, 255, 0.7);
  }
  body[themeStyle="white"] .roadmap__image::before,
  body[themeStyle="white"] .roadmap__image::after {
    background: linear-gradient(
      90deg,
      #f8ffff 12.82%,
      rgba(248, 255, 255, 0) 100%
    );
  }
  body[themeStyle="dark"] .roadmap__image::before,
  body[themeStyle="dark"] .roadmap__image::after {
    background: linear-gradient(
      90deg,
      #221f2c 12.82%,
      rgba(34, 31, 44, 0.72) 49.53%,
      rgba(34, 31, 44, 0) 100%
    );
  }
  body[themeStyle="white"] .roadmap__mobile-image::before,
  body[themeStyle="white"] .roadmap__mobile-image::after {
    background: linear-gradient(
      90deg,
      #f6fffd 12.82%,
      rgba(246, 255, 253, 0.96) 49.24%,
      rgba(246, 255, 253, 0) 100%
    );
  }
  body[themeStyle="dark"] .roadmap__mobile-image::before,
  body[themeStyle="dark"] .roadmap__mobile-image::after {
    background: linear-gradient(
      90deg,
      #221f2c 12.82%,
      rgba(34, 31, 44, 0.96) 49.24%,
      rgba(34, 31, 44, 0) 100%
    );
  }
  body[themeStyle="dark"] .gradient-bg {
    background: #242331;
  }
  body[themeStyle="white"] .gradient-bg {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.4) 0%,
      rgba(242, 255, 250, 0.4) 100%
    );
  }
  body[themeStyle="dark"] .hero-section__image-wrapper::after {
    background: #221f2c;
  }
  body[themeStyle="white"] .hero-section__image-wrapper::after {
    background: white;
  }
  body[themeStyle="white"] .submenu__link:hover,
  body[themeStyle="white"] .submenu__link._active {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(248, 245, 255, 0.9) 100%
    );
    box-shadow: 0px 0px 8px rgba(161, 136, 245, 0.4);
  }
  body[themeStyle="dark"] .submenu__link {
    background: rgba(34, 31, 44, 0.7);
  }
  body[themeStyle="dark"] .submenu__link:hover,
  body[themeStyle="dark"] .submenu__link._active {
    background: #221f2c;
    box-shadow: 0px 0px 10px rgba(161, 136, 245, 0.1);
  }
  body[themeStyle="dark"] .submenu__wrapper::before {
    display: none;
  }
  body[themeStyle="dark"] .submenu__wrapper::after {
    z-index: 1;
  }
  body[themeStyle="white"] .input__wrapper {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
  body[themeStyle="white"] .input__wrapper input,
  body[themeStyle="white"] .input__wrapper textarea  {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0.9) 0%,
      rgba(242, 255, 250, 0.9) 100%
    );
  }
  body[themeStyle="white"] .social-links svg path {
    fill: #221f2c;
  }
  body[themeStyle="dark"] .faq-gradient-border,
  body[themeStyle="dark"] .news-gradient-border {
    background: linear-gradient(
      90deg,
      rgba(255, 255, 255, 0) 1.01%,
      #ffffff 52.57%,
      rgba(255, 255, 255, 0) 100%
    );
  }
  body[themeStyle="white"] .faq-gradient-border,
  body[themeStyle="white"] .news-gradient-border {
    background: linear-gradient(
      90deg,
      rgba(47, 47, 53, 0) 1.01%,
      #2f2f35 52.57%,
      rgba(47, 47, 53, 0) 100%
    );
  }
  body[themeStyle="white"] .features__content {
    background: linear-gradient(
      270deg,
      rgba(137, 162, 251, 0.7) 5%,
      rgba(94, 211, 208, 0.7) 100%

    );
  }
  body[themeStyle="dark"] .features__content {
    background: linear-gradient(270deg, #89a2fb 5%, #5ed3d0  100%);
  }
  body[themeStyle="white"] .features__image-wrapper::after {
    opacity: 0.8;
  }
  body[themeStyle="dark"] .plans__item {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
  }
  body[themeStyle="white"] .plans__item {
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  }
  body[themeStyle="white"] .disabled svg path {
    fill: rgba(48, 48, 56, 0.8);
  }
  body[themeStyle="dark"] .disabled {
    color: rgba(255, 255, 255, 0.5);
  }
  body[themeStyle="white"] .disabled {
    color: rgba(48, 48, 56, 0.5);
  }
  body[themeStyle="dark"] .lottie-light,
  body[themeStyle="white"] .lottie-dark {
    display: none;
  }
  body[themeStyle="dark"] .light {
    display: none;
  }
  body[themeStyle="white"] .dark {
    display: none;
  }
  @media(max-width: 550px){
    body[themeStyle="white"] .button-tab {
      background: rgba(255, 255, 255, 0.7);
      box-shadow: 0px 2px 12px rgba(0, 0, 0, 0.12);
    }
  }
  body[themeStyle="dark"] .earn-points__content {
    background: linear-gradient(270deg, #89a2fb 5%, #5ed3d0 100%);
  }
  body[themeStyle="white"] .earn-points__content {
    background: linear-gradient( 270deg, rgba(137, 162, 251, 0.7) 5%, rgba(94, 211, 208, 0.7) 100% );
  }
  body[themeStyle="dark"] .earn-points__item {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(25px);
    border-radius: 20px;
  }
  body[themeStyle="white"] .earn-points__item {
    background: linear-gradient(134.35deg, rgba(255, 255, 255, 0.8) 0%, rgba(248, 255, 255, 0.4) 98.89%);
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(25px)  
  }
  body[themeStyle="white"] .percent__item {
    background: rgba(255, 255, 255, 0.8);
  }
  body[themeStyle="dark"] .calc__item {
    background: rgba(34, 31, 44, 0.6);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  }
  body[themeStyle="white"] .calc__item {
    background: rgba(255, 255, 255, 0.6);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.08);
  }
  body[themeStyle="white"] .calc__wrapper::after {
    background: linear-gradient(166.44deg, rgba(248, 255, 255, 0.8) 0%, rgba(255, 255, 255, 0.8) 98.22%);
    box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(10px);
    opacity: 0.5;
    width: 108%;
    height: 104.5%;
  }
  body[themeStyle="dark"] .calc__wrapper::after {
    background: linear-gradient(347.09deg, rgba(225, 255, 255, 0.6) 3.99%, rgba(189, 170, 255, 0.6) 98.46%);
  }
  body[themeStyle="white"] .calc{
    border: none;
    box-shadow: none;
  }
  body[themeStyle="white"] .types__slide-button{
    background: rgba(255, 255, 255, 0.8);
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  }
  body[themeStyle="white"] .ntf-types__content{
    background: #FFFFFF;
    box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.16);
  }
  body[themeStyle="white"] .types__slide-item {
    background: linear-gradient(89.27deg, rgba(255, 255, 255, 0.7) 0.59%, rgba(255, 255, 255, 0.21) 99.37%);
    box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
  }
  body[themeStyle="dark"] .button-disabled{
    color: rgba(255, 255, 255, 0.7);
    background: rgba(34, 31, 44, 0.8);
  }
  body[themeStyle="white"] .button-disabled{
    color: rgba(47, 47, 53, 0.7);
  }
  body[themeStyle="dark"] .nft-types__change-button{
    background: linear-gradient(90deg, #312D43 0%, #383253 100%);
    box-shadow: 0px 2px 16px rgba(161, 136, 245, 0.2);
    border-radius: 8px 8px 0px 0px;
  }
  body[themeStyle="white"] .nft-types__change-button{
    background: linear-gradient(90deg, #FAF8FF 0%, #EBE6FF 100%);
    box-shadow: 0px 2px 16px rgba(161, 136, 245, 0.2);
    border-radius: 8px 8px 0px 0px;
  }
}

@media(max-width: 768px){
  .percent__item {
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.16);
    backdrop-filter: blur(25px);
  }
}

.plans__item svg {
  width: 18px;
  min-width: 18px;
  margin-top: 4px;
  margin-right: 25px;
}

@media (max-width: 768px) {
  .plans__item svg {
    margin-right: 19px;
    width: 9px;
    min-width: 11px;
    margin-top: 1px;
  }
  .hero-section-height{
    height: initial;
  }
}

.social-links svg path {
  transition: 0.3s ease all;
}

.social-links a:hover svg path {
  fill: #a67afd;
}

.roadmap__mobile-image::after {
  content: "";
  position: absolute;
  max-width: 150px;
  max-height: 200px;
  height: 100%;
  width: 100%;
  left: 50%;
  top: -6%;
  z-index: 2;
  transform: translateX(-50%) rotate(90deg);
}

.news-form__input {
  outline: none !important;
}

.submenu__link._active {
  color: #a67afd;
}

@layer components {
  .container {
    @apply max-w-[1140px];
  }
  .container__left {
    padding-left: 15px;
  }
  @media (min-width: 1140px) {
    .container__left {
      margin-left: calc((100vw - 1140px) / 2);
    }
  }
  @media (max-width: 1140px){
    .container__left{
      padding-right: 20px;
    }
  }
  @media (min-width: 1460px){
    .roadmap__image::after{
      left: 0 !important;
    }
    .roadmap__image::before{
      right: 0 !important;
    }
  }
  @media (min-width: 1600px){
    .roadmap__image::after{
      left: 1% !important;
    }
    .roadmap__image::before{
      right: 1% !important;
    }
  }
  @media(max-width: 768px){
    .nft__container{
      padding: 0 20px !important;
    }
  }
  @media (min-width: 1800px) {
    .container__left {
      margin: 0 auto;
      max-width: 1140px;
      overflow: visible !important;
    }
    .profile-decor,
    .bottom-decor{
      display: none;
    }
    .hero-section__image {
      max-width: 120%;
    }
    .hero-section::after {
      bottom: -14% !important;
      height: 500px !important;
    }
    .hero-section__image {
      position: relative;
    }
    .hero-section__image-wrapper::after {
      content: "";
      position: absolute;
      height: 500px;
      filter: blur(-6px);
      top: -16%;
      width: 500px;
      background: #221f2c;
      right: -102%;
      filter: blur(58px);
      z-index: 2;
      transition: 0.3s ease all;
      -webkit-transform: translateZ(0);
      -webkit-perspective: 1000;
      -webkit-backface-visibility: hidden;
    }
    section {
      max-width: 1140px;
      margin-left: auto;
      margin-right: auto;
      overflow: visible !important;
    }
    .news-form {
      max-width: none;
    }
    .tree-background {
      max-width: none;
    }
  }
}

.transition-all {
  transition: 0.3s ease all;
}

.gradient-border {
  background: linear-gradient(
    90deg,
    rgba(180, 183, 255, 0) 0%,
    #b4b7ff 48.37%,
    rgba(180, 183, 255, 0) 100%
  );
  width: 100%;
  height: 1px;
}

.gradient-text {
  background: linear-gradient(90deg, #5ed3d0 0%, #8750f3 40.87%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.button {
  font-weight: 700;
  background: linear-gradient(90deg, #5ED3D0 0%, #83A4F6 50.52%, #8750F3 100%);
  border-radius: 16px;
  padding: 17px;
  color: white !important;
}

.header-button{
  background: linear-gradient(90.09deg, #8750F3 0.08%, #89A2FB 49.24%, #5ED3D0 97.67%);
  border-radius: 10px !important;
}

.header .button {
  padding: 12px;
}

.button:hover {
  transform: scale(0.95);
  box-shadow: 0px 0px 12px 1px rgba(166, 122, 253, 0.5);
}

.button:active {
  transform: scale(0.95);
  box-shadow: 0px 0px 13px 1px rgba(166, 122, 253, 0.5);
}

@media (max-width: 1024px) {
  .button:hover {
    transform: initial;
  }
}

.header .button-bordered {
  background: transparent;
  border: 2px solid #a67afd;
  padding: 10px 34.5px;
  color: #a67afd !important;
  border-radius: 10px !important;
}

.header .button-bordered:hover {
  background-color: #a67afd;
  color: white !important;
  box-shadow: none;
}

.header__menu-nav {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.16);
  border-radius: 12px;
}

@media (max-width: 1024px) {
  .header .container {
    padding: 0 33px;
  }
  .container__left {
    padding-left: 33px;
  }
  .container {
    padding: 0 33px;
  }
}

@media (max-width: 768px) {
  .container__left {
    padding-left: 0;
  }
  .container {
    padding: 0 48px;
  }
  .phone-grid__container{
    padding: 0 20px;
  }
  .tree-background__container {
    padding: 0;
  }
  .news-form__container {
    padding: 0;
  }
  .button {
    padding: 13px;
  }
}

.header-menu__wrapper {
  opacity: 0;
  padding: 9px 15px 15px 15px;
  transform: translateX(-50%);
  transition: 0.2s ease all;
}

.header-menu__wrapper._active {
  opacity: 1;
  z-index: 20;
}

.header__menu-burger._active {
  margin-top: 5px;
}

.header__menu-burger._active span:nth-child(2) {
  background: transparent !important;
}

.header__menu-burger._active span:nth-child(2) {
  background: transparent !important;
}

.header__menu-burger._active span:nth-child(1) {
  transform: rotate(45deg);
}

.header__menu-burger._active span:nth-child(3) {
  transform: rotate(-45deg);
  position: absolute;
}

.gradient-decor {
  filter: blur(80px);
  background: linear-gradient(90deg, #5ed3d0 0%, #83a4f6 50.52%, #8750f3 100%);
  z-index: -1 !important;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.submenu__link {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.15);
}

.submenu__body {
  filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.12));
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.submenu__body {
  transform: translate(-8%, 13%);
}

.submenu__wrapper::after {
  bottom: initial;
  top: 30%;
  left: 14%;
  position: absolute;
  content: "";
  width: 35px;
  height: 22px;
  transform: translateY(135%);
  z-index: 2;
}

details > summary {
  list-style: none;
  outline: none !important;
  appearance: none;
  cursor: pointer;
}
details > summary::-webkit-details-marker {
  display: none;
}

.mobile__menu-details[open] summary {
  color: #a67afd;
}

.mobile__menu-details[open] summary svg {
  transform: initial;
}

.mobile__menu-details summary svg {
  margin-right: 0;
  transform: rotate(180deg);
  transition: 0.3s ease all;
}

.header__menu-nav {
  box-shadow: 0px 8px 10px rgba(0, 0, 0, 0.16);
}

.translate-self {
  transform: translate(-50%, -50%) translateZ(0);
}

@media (max-width: 370px) {
  .mobile__menu-buttons {
    flex-direction: column;
  }
  .mobile__menu-buttons a {
    margin-bottom: 10px;
    width: 100%;
    margin-right: 0;
  }
}

._current-lang img {
  filter: drop-shadow(0px 0px 12px #a188f5);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

._current-lang {
  color: #a67afd !important;
}

.current-lang {
  filter: drop-shadow(0px 0px 12px #a188f5);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

._lock {
  overflow: hidden !important;
}

.hero-section::after {
  position: absolute;
  content: "";
  bottom: -1%;
  width: 100%;
  height: 267px;
  z-index: 2;
}

.tree-background__widget {
  background: linear-gradient(
    90deg,
    rgba(94, 211, 208, 0.9) -1.66%,
    rgba(135, 80, 243, 0.9) 100%
  );
  border-radius: 12px;
}

.header-menu__wrapper .header__menu-nav {
  transform: translateX(-100%);
}

.header-menu__wrapper._active .header__menu-nav {
  transform: initial;
}

::-webkit-scrollbar {
  display: none;
}

.left-image::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient(
    125.7deg,
    rgba(104, 137, 255, 0) 57.41%,
    rgba(104, 137, 255, 0.39) 98.43%
  );
  filter: blur(80px);
  transform: rotate(180deg) translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.faq__spoiler[open] .spoiler__arrow {
  transform: rotate(0deg);
}

.input {
  outline: none !important;
}

.news-form {
  background: linear-gradient(270deg, #5ed3d0 46.74%, #89a2fb 100%);
}

.news-form__button {
  border-radius: 8px 0px 0px 8px;
}

.news-form__button:hover {
  transform: initial;
}

.news-form__button::before {
  content: "";
  position: absolute;
  width: 14px;
  height: 12px;
  background: #6279c5;
  right: 0;
  top: -12px;
  clip-path: polygon(0 0, 0% 100%, 100% 100%);
}

.roadmap__image::after {
  content: "";
  position: absolute;
  max-width: 320px;
  height: 100%;
  width: 100%;
  left: 10%;
  top: 0;
  z-index: 2;
}

.roadmap__image::before {
  content: "";
  position: absolute;
  max-width: 320px;
  height: 100%;
  width: 100%;
  right: 10%;
  transform: rotate(180deg);
  top: 0;
  z-index: 2;
}

.roadmap__item {
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
}

.roadmap__item {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

.roadmap__item {
  transition: 0.3s ease all;
}

.roadmap__item-wrapper:nth-child(1) .roadmap__item:hover {
  background-color: #5ed3d0;
}

.roadmap__item-wrapper:nth-child(1) .number {
  background-color: #5ed3d0;
}

.roadmap__item-wrapper:nth-child(2) .roadmap__item:hover {
  background-color: #ffaa5b;
}

.roadmap__item-wrapper:nth-child(2) .number {
  background-color: #ffaa5b;
}

.roadmap__item-wrapper:nth-child(3) .number {
  background-color: #8750f3;
}

.roadmap__item-wrapper:nth-child(3) .roadmap__item:hover {
  background-color: #8750f3;
}

.roadmap__item-wrapper:nth-child(4) .number {
  background-color: #ffee56;
}

.roadmap__item-wrapper:nth-child(4) .roadmap__item:hover {
  background-color: #ffee56;
}

.roadmap__item-wrapper:nth-child(5) .number {
  background-color: #638fff;
}

.roadmap__item-wrapper:nth-child(5) .roadmap__item:hover {
  background-color: #638fff;
}

.roadmap__item .number p {
  text-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
}

.roadmap__mobile-image::before {
  content: "";
  position: absolute;
  max-width: 100px;
  max-height: 150px;
  height: 100%;
  width: 100%;
  z-index: 2;
  left: 34%;
  bottom: -10%;
  transform: translateX(-50%) rotate(270deg);
  background: linear-gradient(90deg, #221f2c 12.82%, rgba(34, 31, 44, 0) 100%);
}

@media (max-width: 768px) {
  .container {
    max-width: none;
  }
  .roadmap__container {
    padding-left: 0;
    padding-right: 20px;
  }
  .hero-section .button {
    transform: translate(-50%) scale(1);
  }
}

@media (max-width: 400px) {
  .roadmap__item p {
    font-size: 10px;
  }
  .roadmap__content {
    max-width: 300px;
  }
  .roadmap__wrapper {
    margin-top: 100px;
  }
  .roadmap__item {
    margin-bottom: 15px;
  }
  .roadmap__mobile-image::before {
    bottom: -7%;
  }
  .roadmap__mobile-image img {
    max-height: 500px;
  }
}

html,
body {
  overflow-x: hidden;
}

.burger-decor {
  background: rgba(122, 128, 255, 0.5);
  filter: blur(50px);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.burger-decor__green {
  background: rgba(126, 255, 193, 0.54);
  filter: blur(50px);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.thumbs {
  margin-right: 22px !important;
}

.thumbs .swiper-slide {
  max-height: 108px;
  transition: 0.3s ease all;
  opacity: 1;
  cursor: pointer;
  position: relative;
}

.thumbs__content::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(65, 77, 185, 0.6);
  border-radius: 6px;
}

.thumbs .swiper-slide-thumb-active {
  opacity: 0;
  position: absolute;
  z-index: -1;
}

.thumbs {
  height: 100% !important;
  max-width: 100px;
  margin-left: 0 !important;
  margin-right: 22px !important;
}

.location-slider .swiper-slide {
  padding: 20px 20px 32px 20px;
  background: linear-gradient(
    161.8deg,
    rgba(137, 162, 251, 0.9) 0.93%,
    rgba(80, 92, 201, 0.9) 92.84%
  );
  border-radius: 12px;
}

.discount-slider .swiper-slide {
  padding: 20px !important;
}

.location-slider {
  margin-right: 22px !important;
  max-width: 280px;
  margin-left: 0 !important;
}

.location-slider .swiper-slide {
  border-radius: 12px;
}

.location-slider__country {
  background: rgba(69, 82, 201, 0.8);
  border-radius: 0px 0px 12px 12px;
}

.thumbs .swiper-wrapper {
  margin-top: 92px;
}

@media (max-width: 1024px) {
  .grid-row-2 {
    grid-row: 2;
  }
}

@media (max-width: 768px) {
  .grid-row-image {
    grid-row: 2;
  }
}

@media (max-width: 650px) {
  .location-swiper__container {
    padding: 0 20px !important;
  }
}

@media (max-width: 400px) {
  .location-swiper__container {
    padding: 0 15px !important;
  }
}

.discount-slider {
  overflow: visible !important;
}

.discount-slider .swiper-slide img {
  max-width: 150px;
  width: 100%;
  margin: 0 auto 0;
}

.discount-slider .swiper-slide-active:nth-child(3) img {
  max-width: 180px;
  width: 100%;
}

.discount-slider .swiper-slide-active img {
  filter: drop-shadow(-3px 16px 13px #666666);
  -webkit-filter: drop-shadow(-3px 16px 13px #666666);
}

.discount-slider .swiper-slide-active:nth-child(3) img {
  transform: translateY(-18%);
}

.discount-slider .swiper-slide-active:nth-child(1) img {
  transform: translate(3%, -18%);
}


.discount-slider .swiper-slide-active:nth-child(2) .discount-slider__image{
  display: flex;
  align-items: center;
}

.discount-slider .swiper-slide-active:nth-child(4) img {
  max-width: 190px;
  margin-right: 20px;
}

.discount-slider .swiper-slide {
  min-height: fit-content;
}

.discount-slider__image {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
  border-radius: 12px;
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  min-height: 240px;
  max-height: 240px;
}

.discount-slider .swiper-slide:nth-child(1),
.discount-slider .swiper-slide:nth-child(2) {
  background: linear-gradient(
    161.8deg,
    rgba(161, 136, 245, 0.9) 0.93%,
    rgba(135, 80, 243, 0.9) 92.84%
  );
}

.discount-slider .swiper-slide:nth-child(3) {
  background: linear-gradient(
    161.8deg,
    rgba(94, 211, 208, 0.9) 0.93%,
    rgba(59, 193, 190, 0.9) 92.84%
  );
}

.discount-slider .swiper-slide:nth-child(4) {
  background: linear-gradient(
    161.8deg,
    rgba(253, 199, 121, 0.9) 0.93%,
    rgba(255, 170, 91, 0.9) 92.84%
  );
}

.discount-slider .swiper-slide:nth-child(4) .discount-slider__image {
  background: linear-gradient(
    161.8deg,
    rgba(255, 255, 255, 0.8) 0.93%,
    rgba(255, 222, 191, 0.8) 92.84%
  );
}

.discount-slider .swiper-slide:nth-child(4) .discount-slider__image {
  display: flex;
  align-items: center;
}

.discount-slider .swiper-slide:nth-child(1) .discount-slider__image,
.discount-slider .swiper-slide:nth-child(2) .discount-slider__image {
  background: linear-gradient(
    161.8deg,
    rgba(245, 242, 255, 0.8) 0.93%,
    rgba(208, 184, 255, 0.8) 92.84%
  );
}

.discount-slider .swiper-slide:nth-child(3) .discount-slider__image {
  background: linear-gradient(
    161.8deg,
    rgba(253, 255, 255, 0.7) 0.93%,
    rgba(188, 255, 253, 0.7) 92.84%
  );
}

.discont-thumbs .swiper-wrapper {
  gap: 30px;
}

.discont-thumbs .swiper-slide:nth-child(4) {
  margin-top: 10px;
}

.swiper-pagination-discont {
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-right: 22px;
}

.swiper-pagination-discont .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: linear-gradient(
    153.43deg,
    rgba(161, 136, 245, 0.2) 0%,
    rgba(127, 95, 233, 0.2) 83.33%
  );
  border-radius: 100%;
  transition: 0.3s ease all;
  cursor: pointer;
}

.swiper-pagination-discont .swiper-pagination-bullet-active {
  background: linear-gradient(180deg, #a188f5 0%, #7e5ee9 100%);
  height: 57px;
  border-radius: 10px;
}

.swiper-pagination .swiper-pagination-bullet {
  width: 16px;
  height: 16px;
  background: linear-gradient(
    153.43deg,
    rgba(161, 136, 245, 0.2) 0%,
    rgba(94, 125, 233, 0.2) 83.33%
  );
  border-radius: 100%;
  transition: 0.3s ease all;
  cursor: pointer;
}

.swiper-pagination .swiper-pagination-bullet-active {
  background: linear-gradient(180deg, #a188f5 0%, #5e7de9 100%);
  height: 57px;
  border-radius: 10px;
}

.swiper-pagination {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

@media (max-width: 640px) {
  .location-slider {
    max-width: 220px;
    margin-right: 14px !important;
  }
  .thumbs {
    margin-right: 14px !important;
  }
  .location-slider .swiper-slide {
    padding: 10px 13px 13px 13px;
  }
  .thumbs {
    max-width: 66px;
  }
  .thumbs .swiper-wrapper {
    margin-top: 67px;
    gap: 0 !important;
  }
  .swiper-pagination-discont .swiper-pagination-bullet-active,
  .swiper-pagination .swiper-pagination-bullet-active {
    height: 39px !important;
    width: 11px;
  }
  .swiper-pagination-discont .swiper-pagination-bullet,
  .swiper-pagination .swiper-pagination-bullet {
    width: 11px;
    height: 11px;
  }
  .discount-slider__image {
    max-height: 190px;
    min-height: 190px;
  }
  .discount-slider .swiper-slide img {
    max-width: 120px;
  }
  .discount-slider .swiper-slide-active:nth-child(3) img {
    max-width: 120px;
  }
  .discount-slider .swiper-slide-active:nth-child(4) img {
    max-width: 150px;
    margin-right: 8px;
  }
  .swiper-pagination-discont {
    margin-right: 14px;
  }
  .discont-thumbs .swiper-wrapper {
    margin-top: 70px !important;
  }
  .discont-thumbs .swiper-slide:nth-child(4) {
    margin-top: 13px;
  }
}

.item-price {
  color: rgba(255, 255, 255, 0.5) !important;
}

.item-price::before {
  background-color: rgba(255, 255, 255, 0.5);
}

.discont-thumbs .swiper-wrapper {
  margin-top: 17px;
}

.discont-thumbs {
  margin-right: 0 !important;
}

.discont-swiper::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient(
    125.7deg,
    rgba(104, 137, 255, 0) 57.41%,
    rgba(104, 137, 255, 0.39) 98.43%
  );
  filter: blur(80px);
  transform: rotate(180deg) translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.submenu__wrapper::before {
  bottom: initial;
  top: 163%;
  left: 14%;
  position: absolute;
  content: "";
  background-color: #f8ffff;
  width: 35px;
  height: 10px;
  transform: translateY(170%);
  z-index: 4;
}

.gradient-decor__sub-menu {
  left: 50%;
  transform: translate(-50%, -50%) !important;
  max-height: 68%;
  z-index: initial !important;
  top: 50%;
  filter: blur(50px);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.news-form__button {
  transition: 0.3s ease all;
  background: linear-gradient(270deg, #88a2fa 0%, #5ed3d0 100%);
}

.number {
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.16);
}

.discount-slider .swiper-slide {
  visibility: hidden;
}

.discount-slider .swiper-slide.swiper-slide-active {
  visibility: visible;
}

.discont-swiper .button-wrapper {
  margin-top: auto;
}

.discount-percent {
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.16);
  color: white !important;
}

@media (max-width: 350px) {
  .thumbs .swiper-wrapper {
    margin-top: 52px;
  }
}

.discount-slider .swiper-slide-active:nth-child(1) .item-price::before,
.discount-slider .swiper-slide-active:nth-child(2) .item-price::before {
  background: linear-gradient(
    161.8deg,
    rgba(161, 136, 245, 0.9) 0.93%,
    rgba(135, 80, 243, 0.9) 92.84%
  );
}

.discount-slider .swiper-slide-active:nth-child(3) .item-price::before {
  background: linear-gradient(
    161.8deg,
    rgba(94, 211, 208, 0.9) 0.93%,
    rgba(59, 193, 190, 0.9) 92.84%
  );
}

.discount-slider .swiper-slide-active:nth-child(4) .item-price::before {
  background: linear-gradient(
    161.8deg,
    rgba(255, 255, 255, 0.8) 0.93%,
    rgba(255, 222, 191, 0.8) 92.84%
  );
}

.footer-lang-wrapper {
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.1);
  border-radius: 6px;
}

.current-style {
  position: static;
  opacity: 1;
}

.theme-style {
  transition: 0.7s ease all;
}

.gpm-link-page .hero-section br {
  display: none !important;
}

.features__content {
  box-shadow: 0px 10px 24px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}

.features__content h2 {
  color: #312d43 !important;
}

.features__image-wrapper::after {
  content: "";
  position: absolute;
  width: 100%;
  height: 90%;
  bottom: 0;
  background-color: #a67afd;
  left: 50%;
  transform: translateX(-50%) translateZ(0);
  opacity: 0.6;
  filter: blur(15px);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
  max-width: 70px;
  z-index: 1;
  transition: 0.3s ease all;
  opacity: 0;
}

@media (max-width: 380px) {
  .features__content {
    padding-left: 20px;
    padding-right: 20px;
  }
}

.parallax {
  background: linear-gradient(270deg, #5ed3d0 46.74%, #89a2fb 100%);
}

.react-parallax-bgimage {
  mix-blend-mode: luminosity;
  opacity: 0.5;
  object-fit: cover;
}

.right-image__decor {
  transform: translateX(-50%);
}

.gpm-link-page .discont-swiper .gradient-decor {
  top: -15%;
}

@media (max-width: 768px) {
  .gpm-link-page .discont-swiper .gradient-decor {
    display: block;
    top: -30%;
    max-width: 200px;
    max-height: 200px;
  }
  .gpm-link-page .discont-swiper {
    position: relative;
  }
  .gpm-link-page .discont-swiper::before {
    position: absolute;
    content: "";
    bottom: 0;
    right: 0;
    background: linear-gradient(
      125.7deg,
      rgba(104, 137, 255, 0) 57.41%,
      rgba(104, 137, 255, 0.39) 98.43%
    );
    width: 100%;
    max-height: 163px;
    height: 100%;
  }
}

.gpm-link-page .image-left {
  position: relative;
}

.mounth-bottom {
  font-size: 14px;
  font-weight: 600;
  margin-top: 10px;
}

.type-free {
  background: #5ed3d0;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 8px;
  color: #2f2f35 !important;
}

.type-premium {
  background: linear-gradient(90deg, #5ed3d0 0%, #89a2fb 100%);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 0px 8px 8px 8px;
  color: #2f2f35 !important;
}

.type-free::after,
.type-premium::after {
  position: absolute;
  content: "";
  width: 12px;
  height: 23px;
  clip-path: polygon(100% 0, 0 100%, 100% 100%);
  background: linear-gradient(172.14deg, #4cb0ae 26.97%, #348987 100%);
  top: 0;
  left: -12px;
}

.plans::before {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  width: 100%;
  z-index: 1;
  height: 50%;
  background: linear-gradient(
    125.7deg,
    rgba(104, 137, 255, 0) 57.41%,
    rgba(104, 137, 255, 0.39) 98.43%
  );
  filter: blur(80px);
  transform: rotate(180deg) translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.gradient-decor__plans {
  transform: translate(-50%, -50%) translateZ(0);
}

@media (max-width: 768px) {
  .mounth-bottom {
    font-size: 11px;
    font-weight: 600;
    margin-top: 6px;
  }
  .type-free::after,
  .type-premium::after {
    position: absolute;
    content: "";
    width: 12px;
    height: 19px;
    clip-path: polygon(100% 0, 0 100%, 100% 100%);
    background: linear-gradient(172.14deg, #4cb0ae 26.97%, #348987 100%);
    top: 0;
    left: -12px;
  }
  .plans::before{
    display: none;
  }
}

.bottom-decor{
  background: linear-gradient(125.7deg, rgba(104, 137, 255, 0) 57.41%, rgba(104, 137, 255, 0.39) 98.43%);
  filter: blur(80px);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

.hero-section-decor{
  transform: translateX(-50%) translateY(-50%) translateZ(0);
}

.gradient-decor__plans{
  -webkit-transform: translate(-50%, -50%) translateZ(0) !important;
}

.gpm-token .hero-section::after,
.digital-team-page .hero-section::after{
  display: none !important;
}

.gpm-token .hero-section .button,
.digital-team-page .hero-section .button{
  transform: none;
}

.left-image-digital::before{
  transform: matrix(-1, 0, 0, 1, 0, 0) translateZ(0) !important;
  width: 60%;
}

.digital-image-right::before {
  position: absolute;
  content: "";
  right: 0;
  bottom: 0;
  width: 100%;
  z-index: 1;
  height: 100%;
  background: linear-gradient( 125.7deg, rgba(104, 137, 255, 0) 57.41%, rgba(104, 137, 255, 0.39) 98.43% );
  filter: blur(80px);
  transform: translateZ(0);
  background: linear-gradient(125.7deg, rgba(104, 137, 255, 0) 57.41%, rgba(104, 137, 255, 0.26) 98.43%);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

@media(max-width: 768px){
  .left-image-digital::before,
  .digital-image-right::before{
    display: none;
  }
}

.roadmap__item-arrow{
  position: absolute;
  left: 47%;
  transform: translateX(-50%);
}

.roadmap__item-arrow1{
  top: -362%;
}

.roadmap__item-arrow2{
  top: -286%;
}

.roadmap__item-arrow3{
  top: -292%;
}

.roadmap__item-arrow4{
  top: -193%;
}

.roadmap__item-arrow5{
  top: -311%;
}

@media(max-width: 1280px){
  .roadmap__item-arrow1{
    top: -380%;
  }
  
  .roadmap__item-arrow2{
    top: -304%;
  }
  
  .roadmap__item-arrow3{
    top: -310%;
  }
  
  .roadmap__item-arrow4{
    top: -211%;
  }
  
  .roadmap__item-arrow5{
    top: -329%;
  }
}

@media(max-width: 1024px){
  .roadmap__item-arrow{
    top: 50%;
    transform: translate(-50%, -50%);
  }
  .roadmap__item-arrow svg{
    width: 28px;
    height: 36px;
  }
  .roadmap__item-arrow1{
    left: -103%;
  }
  .roadmap__item-arrow2{
    left: -78%;
  }
  .roadmap__item-arrow3{
    left: -59%;
  }
  .roadmap__item-arrow4{
    left: -86%;
  }
  .roadmap__item-arrow5{
    left: -110%;
  }
}

.gradient-decor-low-blury{
  filter: blur(50px);
}

@media(max-width: 768px){
  .gradient-decor-low-blury{
    max-height: 219px;
  }
  .mail-phone .gradient-decor-low-blury{
    max-height: none;
  }
}

.mail-tabs .discount-slider{
  max-width: 859px;
  margin: 43px auto 0 auto !important;
  overflow: hidden !important;
  padding-top: 30px;
}

.mail-tabs .discount-slider .swiper-slide{
  visibility: initial;
}

.mail-tabs .discount-slider .swiper-slide img {
  filter: drop-shadow(-3px 16px 13px #666666);
  -webkit-filter: drop-shadow(-3px 16px 13px #666666);
}

.mail-tabs .discount-slider .swiper-slide:nth-child(2) img, .discount-slider .swiper-slide:nth-child(1) img {
  transform: translate(3%, -18%);
}

.mail-tabs .discount-slider .swiper-slide{
  background: linear-gradient( 161.8deg, rgba(161, 136, 245, 0.9) 0.93%, rgba(135, 80, 243, 0.9) 92.84% );
}

.mail-tabs .discount-slider .swiper-slide:nth-child(2) img{
  max-width: 170px;
}

.mail-tabs .discount-slider .swiper-slide:nth-child(3) .discount-slider__image{
  display: flex;
  align-items: center;
}

.mail-tabs .discount-slider .swiper-slide:nth-child(3) img{
  max-width: 170px;
}

.button-tab{
  font-size: 20px;
  font-weight: 700;
  padding: 15px;
  border-radius: 40px;
  width: 100%;
  max-width: 229px;
  text-align: center;
  position: relative;
  z-index: 3;
  line-height: 119%;
}

@media(max-width: 768px){
  .button-tab{
    font-size: 16px;
    padding: 12px;
  }
}

@media(max-width: 550px){
  .button-tab{
    max-width: 83px;
    min-width: 83px;
    border-radius: 6px;
    background: #312D43;
  }
  .mobile-arrow{
    display: block;
  }
}

.button-tab.active{
  background: linear-gradient(93.93deg, #A188F5 0%, #8750F3 100%) !important;
  box-shadow: 1px 4px 16px rgba(0, 0, 0, 0.08);
}

.gradient-decor-tabs{
  background: linear-gradient(141.45deg, rgba(135, 80, 243, 0.4) 13.88%, rgba(94, 211, 208, 0.4) 86.77%);
  filter: blur(100px);
  -webkit-transform: translateZ(0);
  -webkit-perspective: 1000;
  -webkit-backface-visibility: hidden;
}

@media(max-width: 900px){
  .container-tabs{
    padding: 0 !important;
  }
  .mail-tabs .discount-slider{
    max-width: none;
  }
}

@media(max-width: 640px){
  .mail-tabs .discount-slider .swiper-slide:nth-child(3) img{
    max-width: 140px;
  }
  .mail-tabs .discount-slider .swiper-slide:nth-child(2) img{
    max-width: 140px;
  }
}

@media(max-width: 400px){
  .mail-tabs .discount-slider .swiper-slide:nth-child(1) img{
    max-width: 110px;
  }
  .mail-tabs .discount-slider .swiper-slide:nth-child(2) img{
    max-width: 120px;
  }
  .mail-tabs .discount-slider .swiper-slide:nth-child(3) img{
    max-width: 120px;
  }
}

@media(max-width: 768px){
  .gradient-decor-tabs{
    left: 50%;
    transform: translateX(-50%) translateZ(0);
    top: 17%;
    filter: blur(50px);
  }
}

@media(max-width: 414px){
  .earn-points__content{
    padding-right: 30px;
    padding-left: 30px;
  }
}

.gpm-travel-hero__slider .swiper-slide img{
  max-width: 233px;
  transition: .3s ease all;
}

.gpm-travel-hero__slider .swiper-slide-active img{
  max-width: 340px;
}

.gpm-travel-hero-section__content{
  grid-template-columns: 0.9fr 1fr;
  overflow: hidden;
  width: 100%;
}

.gpm-travel-hero__slider .swiper-slide-active h4{
  font-size: 16px;
}

@media(max-width: 768px){
  .gpm-travel-hero__slider .swiper-slide img{
    max-width: none;
    transition: .3s ease all;
  }
  .gpm-travel-hero__slider .swiper-slide{
    scale: 0.80;
    transition: .3s ease all;
  }
  
  .gpm-travel-hero__slider .swiper-slide-active img{
    max-width: none;
  }
  .gpm-travel-hero__slider .swiper-slide-active{
    scale: 1;
  }
  .container__left{
    padding-right: 0;
  }
  .gpm-travel-hero__slider .swiper-slide-active h4{
    font-size: 14px;
  } 
}

.location-arrow{
  display: none !important;
}

.location-swiper .gradient-decor{
  display: none !important;
}

.gpm-travel-page .location-swiper .gradient-decor{
  display: block !important;
}

.gpm-travel-page .location-arrow{
  display: block !important;
}

@media(max-width: 1124px){
  .location-arrow{
    left: 7%;
  }
}

@media(max-width: 1080px){
  .location-arrow{
    left: 5%;
  }
}

@media(max-width: 1060px){
  .location-arrow{
    left: 3%;
  }
}

@media(max-width: 1024px){
  .gpm-travel-page .location-arrow{
    display: none !important;
  }
}

.benefits-slider{
  z-index: 3 !important;
}

.benefits-slider__content::before{
  background: linear-gradient(357.37deg, rgba(47, 69, 68, 0) 2.34%, rgba(29, 37, 37, 0.7) 97.96%);
  border-radius: 20px;
  transform: matrix(-1, 0, 0, 1, 0, 0);
}

.swiper-pagination-travel .swiper-pagination-bullet{
  width: 16px;
  height: 16px;
  background: linear-gradient(153.43deg, rgba(161, 136, 245, 0.2) 0%, rgba(94, 125, 233, 0.2) 83.33%);
  border-radius: 10px;
  transition: .3s ease all;
  cursor: pointer;
}

.swiper-pagination-travel .swiper-pagination-bullet-active{
  width: 80px;
  background: linear-gradient(180deg, #A188F5 0%, #5E7DE9 100%);
  border-radius: 10px;
}

.benefits-slider__title2 b{
  background: linear-gradient(108.08deg, #5ED3D0 0%, #8750F3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}


@media(max-width: 768px){
  .benefits-slider__title2 b{
    display: block;
  }
  .button{
    border-radius: 8px !important;
  }
}

@media(max-width: 768px){
  .swiper-pagination-travel .swiper-pagination-bullet{
    width: 11px;
    height: 11px;
  }
  .swiper-pagination-travel .swiper-pagination-bullet-active{
    width: 48px;
  }
  .benefits-slider__title2 b{
    display: block;
  }
}

.benefits-slider__content3{
  background-position: bottom;
}

.benefits-slider__title3{
  max-width: 180px;
}

.percents{
  background: linear-gradient(270deg, #5ED3D0 46.74%, #89A2FB 100%);
}

.percent__number{
  background: linear-gradient(108.08deg, #5ED3D0 0%, #8750F3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.discont-swiper .discount-slider .swiper-slide:nth-child(4){
  margin-top: 14px;
}

.discont-thumbs{
  overflow: visible !important;
}

@media(max-width: 768px){
  .discont-swiper .discount-slider .swiper-slide:nth-child(4){
    margin-top: 0;
  }  
}

.tab-slider .swiper-slide{
  height: 100% !important;
}

.gpm-link-page .hero-section h1{
  min-height: 249px;
}

.reseller-page .hero-section::after{
  display: none !important;
}

@media(max-width: 1280px){
  .gpm-link-page .hero-section h1{
    min-height: initial;
  }
  .digital-team-page .hero-section h1{
    min-height: initial;
  }  
}

@media(max-width: 768px){
  .reseller-page .hero-section .button {
    transform: translate(0) scale(1);
  }
}

.gpm-token .features__text{
  display: none;
}

.gpm-token .features__title{
  font-size: 16px;
  max-width: 150px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 20px;
}

.gpm-token .features__section-title{
  max-width: 150px;
  line-height: 130%;
}

.calc{
  border: 13px solid rgba(234, 255, 255, 0.2);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.16);
  backdrop-filter: blur(10px);
  position: relative;
  z-index: 3;
}

.calc__wrapper::after{
  position: absolute;
  width: 105%;
  height: 102.5%;
  z-index: 1;
  border-radius: 34px;
  content: '';
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}



/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.calc__button.active{
  color: #8750F3;
}

@media(max-width: 420px){
  .calc__wrapper{
    width: 100%;
  }
  .calc{
    width: 100%;
  }
}

@media(max-width: 400px){
  .calc__container{
    padding: 0 25px;
  }
}

.calc__wrapper .gradient-decor-low-blury{
  max-height: none !important;
}

@media(max-width: 830px){
  .tree-man{
    display: none;
  }
}

.burger-decor__green,
.burger-decor{
  translate: 241%;
}

.burger-decor{
  top: -300% !important;
}

.header-menu__wrapper._active .burger-decor,
.header-menu__wrapper._active .burger-decor__green{
  translate: initial;
  top: 300% !important;
}

.fade-enter {
  opacity: 0.1;
}
.fade-enter-active {
  opacity: 1;
  transition: 1s ease all;
}
.fade-exit {
  opacity: 1;
  display: none;
}
.fade-exit-active {
  opacity: 0.1;
  display: none;
}

.types__slide-title{
  background: linear-gradient(90deg, #5ED3D0 0%, #8750F3 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  text-fill-color: transparent;
}

.types__slide-button{
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.16);
  transition: .3s ease all;
}

.types__slide-item{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.types__slide-image{
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.16);
}

.types-decor{
  z-index: 2 !important;
}

.types__slide-button.active{
  background: #A188F5 !important;
}

.nft-types__change-button{
  background: linear-gradient(90deg, #312D43 0%, #383253 100%);
  box-shadow: 0px 2px 16px rgba(161, 136, 245, 0.2);
  border-radius: 8px 8px 0px 0px;
}

.nft-types__change-button.active{
  background: linear-gradient(270deg, #5ED3D0 0%, #A188F5 100%) !important;
  box-shadow: 0px 2px 16px rgba(161, 136, 245, 0.6) !important;
}

@media(max-width: 768px){
  .types__slide-button{
    border-radius: 6px;
    height: 44px;
  }
  .nft-types__change-button{
    border-radius: 6px !important;
  }
}

.ntf-button__arrow.active{
  opacity: 1;
}

.active-tab1{
  background: linear-gradient(93.93deg, #FFB77A 0%, #FF965B 100%);
}

.active-tab2{
  background: linear-gradient(93.93deg, #A188F5 0%, #8750F3 100%);
}

.active-tab3{
  background: linear-gradient(93.93deg, #5ED3D0 0%, #22B8B4 100%);
}

.slider-1 .swiper-slide{
  background: linear-gradient(161.8deg, #FDC779 0.93%, #FFAA5B 92.84%) !important;
}

.slider-1 .discount-slider__image{
  background: linear-gradient(161.8deg, rgba(255, 255, 255, 0.8) 0.93%, rgba(255, 222, 191, 0.8) 92.84%) !important;
}

.slider-2 .swiper-slide{
  background: linear-gradient(161.8deg, rgba(169, 146, 245, 0.9) 0.93%, rgba(146, 96, 243, 0.9) 92.84%) !important;
}

.slider-2 .discount-slider__image{
  background: linear-gradient(161.8deg, rgba(245, 242, 255, 0.8) 0.93%, rgba(208, 184, 255, 0.8) 92.84%) !important;
}

.slider-3 .swiper-slide{
  background: linear-gradient(161.8deg, rgba(94, 211, 208, 0.9) 0.93%, rgba(59, 193, 190, 0.9) 92.84%) !important;
}

.slider-3 .discount-slider__image{
  background: linear-gradient(161.8deg, rgba(248, 255, 255, 0.8) 0.93%, rgba(188, 255, 253, 0.8) 92.84%) !important;
}

.slider-1 .item-price::before{
  background: linear-gradient(161.8deg, rgba(255, 255, 255, 0.8) 0.93%, rgba(255, 222, 191, 0.8) 92.84%) !important;
}

.slider-2 .item-price::before{
  background: linear-gradient(161.8deg, rgba(245, 242, 255, 0.8) 0.93%, rgba(208, 184, 255, 0.8) 92.84%) !important;
}

.slider-3 .item-price::before{
  background: linear-gradient(161.8deg, rgba(248, 255, 255, 0.8) 0.93%, rgba(188, 255, 253, 0.8) 92.84%) !important;
}

.home-page .image-right{
  padding-top: 216px;
}

.static-image{
  margin-top: 60px;
}

@media(max-width: 768px){
  .static-image{
    margin-top: 30px;
  }
}

@media (max-width: 400px){
  .roadmap__item-arrow4 {
      left: -98%;
  }
}

.digital-team-page .hero-section .gradient-text{
  display: none;
}

.digital-team-page .hero-section br{
  display: none;
}

.home-page section:nth-child(1) h2{
  font-size: 48px;
}

@media(max-width: 1024px){
  .home-page section:nth-child(1) h2{
    font-size: 35px;
  }
}

@media(max-width: 768px){
  .home-page section:nth-child(1) h2{
    font-size: 28px;
  }
  .home-page section:nth-child(1){
    padding-top: 130px;
  }
  .home-page section:nth-child(4){
    padding-top: 0;
    padding-bottom: 35px;
  }
  .digital-team-page .hero-section{
    padding-bottom: 0;
  }
  .digital-team-page .left-image{
    padding-top: 0;
  }
  .digital-team-page .image-right{
    padding-top: 0;
    padding-bottom: 35px;
  }
  .digital-team-page .image-left{
    padding-top: 35px;
  }
  .digital-team-page .image-left h2{
    margin-top: 0;
  }
  .digital-team-page .tree-background{
    margin: 35px 0;
  }
  .digital-team-page .faq{
    padding-top: 35px;
    padding-bottom: 35px;
  }
  .reseller-page .hero-section{
    padding-bottom: 0;
  }
  .chart{
    padding-bottom: 35px;
  }
  .reseller-page .image-right{
    padding: 35px 0;
  }
  .faq{
    padding-bottom: 35px;
  }
  .gpm-token .features{
    margin-top: 0;
    margin-bottom: 0;
  }
  .gpm-token section:nth-child(3){
    padding-bottom: 0;
  }
  .gpm-token .location-swiper{
    padding-top: 0;
  }
  .gpm-token .location-swiper{
    padding-bottom: 0;
  }
  .gpm-token section:nth-child(9){
    margin-top: 40px;
  }
  .gpm-link-page .image-right{
    padding: 0;
  }
  .reseller-page .hero-section{
    margin-top: 140px;
  }
} 

.image__3{
  margin: 5px auto 10px auto;
}

.resellerFeatures{
  font-size: 25px;
}
